@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
    
 


    --amplify-components-field-label-color: #000000;

    --amplify-components-fieldcontrol-border-color: #5e5e5e;

    --amplify-colors-font-primary: #000000;
    --amplify-colors-font-secondary:rgb(121, 121, 121);
    --amplify-colors-font-tertiary:#f2fe45;
    
    --amplify-colors-font-hover: #2568FB;
    --amplify-colors-font-interactive: #163d91;
    
    --amplify-components-button-primary-color: rgb(255, 255, 255);
    --amplify-components-button-primary-background-color: #2568FB;
    --amplify-components-button-primary-hover-background-color: #1d4cb1;
    --amplify-components-button-primary-hover-color: rgb(255, 255, 255) ;



  

}


