@import "colors.top5b.css";






.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.App {
  text-align: left;
  min-height: 10vh;
  
  

}

.Body {
  
  margin: 0;
  background-color:  #0F4C5C;
  max-width: 100vw ;
  
}



.editButton {
height: 1vw;
width: 1vw;
text-align: center;
border-width: 1px;

}

.optBtn {


  font-size: calc(5px + 1vw);
  min-width: calc(15px + 9vw);
  border-radius: 30px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  
  }

  .optBtn:hover {
opacity: .7;
    }
  
    .blankBtn {

      background-color: inherit;
      border-style: none;
      border-width: 0px;
      
      }


  .navBarLink3 {


    font-size: calc(4px +  1.0vw);
    min-width: 10.2vw;
    border-radius: 30px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    padding-left: 0vw;
    padding-right:0vw;
    }
    
    .navBarLink3blue {
    font-size: calc(4px +  1.0vw);
    min-width: 10.2vw;
    padding-left:0vw;
    padding-right:0vw;
    border-radius: 30px;
    border-color: #163f99;
    border-style: solid;
    border-width: 1px;
    }
    
    
.navBarLink3orange {
  font-size: calc(4px +  1.0vw);
  min-width: 10.15vw;
  max-width: 10.15vw;
  padding-left:0vw;
  padding-right:0vw;
  border-radius: 30px;
  border-color: #c26e20;
  border-style: solid;
  border-width: 1px;
}

    
    


.serverLink3 {


  font-size: calc(4px +  1.0vw);

  border-radius: 30px;
  border-color: black;
  border-style: solid;
  border-width: 0px;

  width: 175px ;
  }
  

  .serverLink3blue {
  font-size: calc(4px +  1.0vw);


  border-radius: 30px;
  border-color: #163f99;
  border-style: solid;
  border-width: 0px;
  width: 175px ;
  }
  


  .serverLink3blank {


    font-size: calc(4px +  1.0vw);
  
    border-radius: 30px;
    border-color: black;
    border-style: solid;
    border-width: 0px;
  
    width: 175px ;
    }
    

.Mid-bar {
font-Size:2.2vw;

min-width:48.25vw;
max-width:48.25vw
}



.navBarTitleAuth {
  
  font-size: 2.7vw;
  font-weight: bold;
 
 
}

.pageContent {
  text-align: center;
  
}

.pageContentA {
  text-align: center;
}



.helpContent {
  text-align: center;
}


.pageContentBold {
  text-align: center;
  font-weight: bold;
}

.pageContentLeft {
  text-align: left;
  font-size: 16px;
}

 

.pageContentSmall {
  text-align: center;
  font-size: 14px;
  margin-left: 5%;
  margin-right: 5%; 
}

.pageContentSmallLeft {
  text-align: left;
  font-size: 14px;
  color: red;
  
}



.pageContentMed {
  text-align: center;
  font-size: 23px;
  color: red;
}

.pageContentMedLeft {
  text-align: left;
  font-size: 23px;
}

.pageContentMedBold {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
}




.pageContentMed2 {
  text-align: center;
  
  font-Size:calc(5px + 3.4vh);
}




.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vh);

}

.App-logo {
  width: 4.7vw;
}


.App-processing {
  animation: blinker 1s linear infinite;
}

  
  @keyframes blinker {
  50% {
  opacity: 0;
  }
  }



  
.navbarPC {
  visibility: visible;
  display: block;
}

.navbarMobile {
  visibility: hidden;
  display: none;
}


.navBarTitle {
  font-size: 5vw;  

}



.navBarBox {
  
top :0;
text-align: center;
font-weight: bold;
position: relative;
z-index: 20;

align-content: 'center';
align-items: 'center';
height: auto;
}






@media screen and (min-height: 1000px) and (min-width: 690px) and (max-width: 1199px ) {

  .cType { 
    background-color: purple;
    color: white;
  }

  .cTabletLong { 
    display: flex; 
    visibility: visible;
  }

  .cMed { 
    display: none; 
    visibility: hidden;
  }

 
  
  
@media screen and (min-height: 1300px)  {
  .cType {
    background-color: rgb(108, 121, 78);
    color: rgb(0, 0, 0);
  }

.cTabletLong2 { 
display: flex; 
visibility: visible;
}

.cTabletLong { 
display: none; 
visibility: hidden;
}




}

 
 }






@media screen and (max-width: 689px) {

.cType {
  background-color: rgb(173, 3, 3);
  color: white;
}


.navbarMobile {
visibility: visible;
display: block;
}

.navbarPC {
visibility: hidden;
display: none;
}

.pageContentH {

overflow: hidden;

}

.pageContentHedderH {

font-size: 5.7vw
}

.pageContentHedderP {

font-size: 5.7vw
}


.mid-bar {
font-size: 3.0vw ;


}

.navBarTitle {
font-size: 5.9vw;  

}


.cMobile { 
  display: flex; 
  visibility: visible;
}


.cMed { 
  display: none; 
  visibility: hidden;
}





.footText{

  font-size: 7px;
  background-color:inherit;
  color: inherit;
  }
  



  @media screen and (min-height: 1000px)  {
    .cType {
      background-color: rgb(219, 126, 126);
      color: white;
    }
  
.cMobileLong { 
  display: flex; 
  visibility: visible;
}

.cMobile { 
  display: none; 
  visibility: hidden;
}





@media screen and (min-height: 1300px)  {
  .cType {
    background-color: rgb(121, 78, 78);
    color: rgb(0, 0, 0);
  }

.cMobileLong2 { 
display: flex; 
visibility: visible;
}

.cMobileLong { 
display: none; 
visibility: hidden;
}




}






  }




}




@media screen and (min-width: 1200px) {

.cType {
  background-color: yellow;
  
}

.footText{


font-size: 14px;
}

.navbarMobile {
visibility: hidden;
display: none;
}
.navbarPC {
visibility: visible;
display: block;
}




.mid-bar {
  font-Size:  calc(1.7vw + 10px);


}

.pageContentHedderH {

  font-size: 4.8vw
}

.pageContentHedderP {

  font-size: 4.8vw
}


  .cPC { 
    display: flex; 
    visibility: visible;
  }

  .cMed { 
    display: none; 
    visibility: hidden;
  }
  
  .cMobile { 
    display: none; 
    visibility: hidden;
  }
  

  .navBarLink3 {


    font-size: 1.3vw;
    
  }

  .navBarLink3blue {
    font-size: 1.3vw;
   
  }
  
  
  
  .navBarLink3orange {
    font-size: 1.3vw;
    
  }
  
  .navBarTitle {
    font-size: 4.5vw;  
 
}



.navBarBox {
  height: auto;
 }





 @media screen and (min-height: 1000px)  and (max-width: 1949px ) {

  .cType {
    background-color: green;
    color: white;
  }

  



  @media screen and (min-height: 1300px)  {
    .cType {
      background-color: rgb(97, 121, 78);
      color: rgb(0, 0, 0);
    }
  



  }
  
 

 }



 @media screen and (min-width: 1950px) and (max-width: 2849px) {

  .cType {
    background-color: blue;
    color: white;
  }




.navBarTitle {

font-size: 88px;  
}



.navBarLink3 {


font-size: 25px;
min-width: 200px;

}

.navBarLink3blue {
font-size: 25px;
min-width: 200px;

}



.navBarLink3orange {
font-size: 25px;
min-width: 200px;

}

.pageContentHedderH {
text-align: center;  
font-size: 93px;


}

.pageContentHedderP {
text-align: center;  
font-size: 93px;


}


.mid-bar {
font-Size: 43px;
padding-bottom: 10px;

}




@media screen and (min-height: 1000px) {


.cType {
  background-color: rgb(87, 87, 255);
  color: rgb(0, 0, 0);
}




 @media screen and (min-height: 1300px)  {
  .cType {
    background-color: rgb(121, 78, 108);
    color: rgb(0, 0, 0);
  }




}



}


 
 }









 @media screen and (min-width: 2850px) {

  .cType {
    background-color: orange;
    color: white;
  }




.navBarTitle {

font-size: 88px;  
}





.navBarLink3 {


font-size: 25px;
min-width: 200px;

}

.navBarLink3blue {
font-size: 25px;
min-width: 200px;

}



.navBarLink3orange {
font-size: 25px;
min-width: 200px;

}

.pageContentHedderH {
text-align: center;  
font-size: 93px;
padding-top: -8px;
    

}

.pageContentHedderP {
  text-align: center;  
  font-size: 93px;


}



.mid-bar {
font-Size: 43px;
padding-bottom: 10px;

}



  @media screen and (min-height: 1000px) {
    

    .cType {
      background-color: rgb(54, 120, 196);
      color: white;
    }
  



     @media screen and (min-height: 1300px)  {
      .cType {
        background-color: rgb(106, 121, 78);
        color: rgb(0, 0, 0);
      }


  
    }




  }





   
   }









  }
